import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  Avatar,
  Bar,
  chain,
  FilterButton,
  Icon,
  ToggleButton,
  Token,
} from '@revolut/ui-kit'
import { useQuery } from '@src/utils/queryParamsHooks'
import { difference, intersection, isEqual } from 'lodash'
import { AvatarType } from '@src/interfaces/employees'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import FilterSelect from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { ReviewerRelation } from '@src/interfaces/performance'
import { OptionInterface } from './common'

interface Props {
  options: OptionInterface[]
  quickFilters: QuickFilter[]
  onFilterChange?: (options: OptionInterface[] | undefined) => void
  prefix?: string
  isClearable?: boolean
  hideDropdown?: boolean
  showFilters?: boolean
  hideAll?: boolean
  className?: string
  filterKey: string
  labelAll?: string
  labelDropdown?: string | React.ReactNode
  setActiveFilters?: (activeAvatars: QuickFilter[]) => void
  isNewDesign?: boolean
  additionalFilter?: React.ReactNode
}

const Label = styled.div`
  font-size: 13px;
  margin-right: 8px;
`
const ChipContainer = styled.div``
const Container = styled.div`
  display: flex;
  align-items: center;
`

const ClearFilter = styled.button`
  font-size: 14px;
  color: ${Token.color.blue};
  margin-left: 8px;
`

export interface QuickFilter {
  full_name?: string
  key: ReviewerRelation | string
  name: string
  filters: number[]
  avatar?: AvatarType | null
}

const Filter = ({
  options,
  setActiveFilters,
  quickFilters,
  onFilterChange,
  prefix,
  showFilters,
  hideAll,
  className,
  filterKey,
  hideDropdown,
  isClearable,
  labelAll = 'All',
  isNewDesign = false,
  additionalFilter,
  labelDropdown = 'All reviewers',
}: Props) => {
  const popoverAnchor = useRef<HTMLDivElement>(null)
  const { query, changeQueryParam, deleteQueryParam } = useQuery()
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<number[]>([])
  const queryValues = query[filterKey]?.split(',').map(Number)

  const optionsFunc = async (): Promise<{
    options: OptionInterface[]
  }> => {
    return {
      options,
    }
  }

  const quickFiltersRecordByRelation = quickFilters.reduce<
    Record<QuickFilter['key'], QuickFilter>
  >((acc, filter) => {
    acc[filter.key] = filter
    return acc
  }, {})

  useEffect(() => {
    if (!queryValues) {
      setActiveFilters && setActiveFilters(quickFilters)
    }
  }, [quickFilters])

  const clearFilter = () => {
    deleteQueryParam(filterKey)
    if (onFilterChange) {
      onFilterChange(undefined)
    }
  }

  const toggleFilter = () => {
    setOpenFilter(!openFilter)
  }

  const handleFilterChange = (chosenFilters: OptionInterface[]) => {
    if (!chosenFilters.length) {
      deleteQueryParam(filterKey)
      setActiveFilters?.(quickFilters)
      clearFilter()
      return
    }

    const { activeFilters, filterIdsString } = chosenFilters.reduce<{
      activeFilters: QuickFilter[]
      filterIdsString: string
    }>(
      (acc, { relation, id }, index) => {
        const activeFilter = relation && quickFiltersRecordByRelation[relation]
        activeFilter && acc.activeFilters.push(activeFilter)

        acc.filterIdsString += `${index > 0 ? ',' : ''}${id}`

        return acc
      },
      { activeFilters: [], filterIdsString: '' },
    )

    setActiveFilters?.(activeFilters)

    changeQueryParam(filterKey, filterIdsString)

    onFilterChange?.(chosenFilters)

    toggleFilter()
  }

  const applyQuickFilter = (relation: string, isSelected: boolean) => {
    if (isSelected) {
      clearFilter()
      return
    }

    const filtersList = quickFiltersRecordByRelation[relation]!.filters
    const { selectedFilters: filters, filterIdsQueryParam } =
      createFiltersParamsObject(filtersList)

    changeQueryParam(filterKey, filterIdsQueryParam)
    if (onFilterChange) {
      onFilterChange(filters)
    }
  }

  const applyQuickFilterMultiple = (quickFilter: QuickFilter, isSelected: boolean) => {
    const { key: relation } = quickFilter
    const filtersList = quickFiltersRecordByRelation[relation]?.filters || []

    setSelectedFilters(prevFilters => {
      return applyQuickFiltersMultipleHandlers(
        isSelected
          ? difference(prevFilters, filtersList)
          : [...new Set(prevFilters.concat(filtersList))],
      )
    })
  }

  const applyQuickFiltersMultipleHandlers = (updatedFilters: number[]) => {
    if (setActiveFilters) {
      const selectedActiveFilters = quickFilters.filter(({ filters }) =>
        filters.some(filter => updatedFilters.includes(filter)),
      )
      setActiveFilters(
        selectedActiveFilters.length > 0 ? selectedActiveFilters : quickFilters,
      )
    }

    const { selectedFilters: filters, filterIdsQueryParam } =
      createFiltersParamsObject(updatedFilters)

    changeQueryParam(filterKey, filterIdsQueryParam)

    if (onFilterChange) {
      onFilterChange(filters)
    }

    return updatedFilters
  }

  const createFiltersParamsObject = (updatedFilters: number[]) => {
    return options.reduce<{
      selectedFilters: OptionInterface[]
      filterIdsQueryParam: string
    }>(
      (acc, option) => {
        if (updatedFilters.includes(+option.id)) {
          acc.selectedFilters.push(option)
          acc.filterIdsQueryParam += acc.filterIdsQueryParam ? `,${option.id}` : option.id
        }
        return acc
      },
      { selectedFilters: [], filterIdsQueryParam: '' },
    )
  }

  const label =
    queryValues && queryValues.length
      ? chain('Selected', queryValues.length)
      : labelDropdown
  const isSelectAll = options.some(o => queryValues?.includes(+o.id))

  const renderFilterAllButton = isNewDesign ? (
    <FilterButton
      onClick={toggleFilter}
      active={isSelectAll}
      useIcon="Filter"
      aria-pressed={isSelectAll}
    >
      {label}
    </FilterButton>
  ) : (
    <ToggleButton
      onToggle={toggleFilter}
      checked={options.some(o => queryValues?.includes(+o.id))}
      useIcon="Filter"
    >
      {label}
    </ToggleButton>
  )
  return (
    <Container className={className} data-testid={`filter-${filterKey}`}>
      {prefix && <Label>{prefix}</Label>}
      {showFilters && isNewDesign && (
        <Bar pr="s-4">
          {!hideAll && (
            <FilterButton
              pl="s-4"
              aria-pressed={!queryValues}
              useIcon={
                <Avatar
                  size={32}
                  useIcon={
                    <Icon name="People" size={20} color={Token.color.popoverBackground} />
                  }
                />
              }
              onClick={() => {
                clearFilter()
                selectedFilters?.length && setSelectedFilters([])
                setActiveFilters && setActiveFilters(quickFilters)
              }}
              active={!queryValues}
            >
              {labelAll}
            </FilterButton>
          )}
          {quickFilters.map(quickFilter => {
            if (!quickFilter.filters.length) {
              return null
            }

            const isSelected = !!intersection(queryValues, quickFilter.filters).length

            return quickFilter.avatar || quickFilter.full_name ? (
              <FilterButton
                aria-pressed={isSelected}
                pl="s-4"
                useIcon={
                  <UserWithAvatar
                    pr="s-8"
                    size={32}
                    uuid={quickFilter.full_name}
                    id={quickFilter.key}
                    full_name={quickFilter.full_name}
                    avatar={quickFilter.avatar}
                    compact
                    asText
                  />
                }
                key={quickFilter.key}
                onClick={() => {
                  applyQuickFilterMultiple(quickFilter, isSelected)
                }}
                active={isSelected}
              >
                {quickFilter.name}
              </FilterButton>
            ) : (
              <FilterButton
                key={quickFilter.key}
                onClick={() => {
                  applyQuickFilterMultiple(quickFilter, isSelected)
                }}
                aria-pressed={isSelected}
                active={isSelected}
              >
                {quickFilter.name}
              </FilterButton>
            )
          })}

          {!hideDropdown && (
            <ChipContainer ref={popoverAnchor}>{renderFilterAllButton}</ChipContainer>
          )}
          {additionalFilter}
        </Bar>
      )}
      {showFilters && !isNewDesign && (
        <Bar>
          {!hideAll && (
            <ToggleButton
              onClick={e => {
                e.preventDefault()
              }}
              onToggle={() => {
                clearFilter()
              }}
              checked={!queryValues}
            >
              {labelAll}
            </ToggleButton>
          )}
          {quickFilters.map(quickFilter => {
            if (!quickFilter.filters.length) {
              return null
            }
            const isSelected = isEqual(queryValues?.sort(), quickFilter.filters?.sort())
            return (
              <ToggleButton
                key={quickFilter.key}
                onClick={e => {
                  e.preventDefault()
                }}
                onToggle={() => {
                  applyQuickFilter(quickFilter.key, isSelected)
                }}
                checked={isSelected}
              >
                {quickFilter.name}
              </ToggleButton>
            )
          })}
          {!hideDropdown && (
            <ChipContainer ref={popoverAnchor}>{renderFilterAllButton}</ChipContainer>
          )}
        </Bar>
      )}
      {!showFilters && isNewDesign && (
        <ChipContainer ref={popoverAnchor}>{renderFilterAllButton}</ChipContainer>
      )}
      {popoverAnchor.current && (
        <FilterSelect
          anchorRef={popoverAnchor}
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          value={options.filter(o => queryValues?.includes(+o.id))}
          onChange={handleFilterChange}
          selector={optionsFunc}
        />
      )}
      {isClearable && !!queryValues ? (
        <ClearFilter type="button" onClick={clearFilter}>
          Clear Filter
        </ClearFilter>
      ) : null}
    </Container>
  )
}

export default Filter
