import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { QueryObserverResult } from 'react-query'
import { ActionButton, MoreBar, TableWidget, Text, VStack } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  confirmImportCompensationBands,
  exportCompensationBandsUploadSessionData,
  getCompensationBandsUploadSession,
  useGetCompensationBandsUploadSessionData,
} from '@src/api/importData'
import { ROUTES } from '@src/constants/routes'
import {
  importBandsActionColumn,
  importBandsBenchmarkTypeColumn,
  importBandsLocationColumn,
  importBandsLowerBandColumn,
  importBandsSeniorityColumn,
  importBandsSeniorityLevelColumn,
  importBandsSpecialisationColumn,
  importBandsOnTargetBonusColumn,
  importBandsUpperBandColumn,
} from '@src/constants/columns/importCompensationBands'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  ImportBandsInterface,
  ImportBandsSessionInterface,
} from '@src/interfaces/importCompensationBands'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import PageLoading from '@src/components/PageLoading/PageLoading'
import Stat from '@components/Stat/Stat'
import SuccessWidget from '@src/components/SuccessWidget/SuccessWidget'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import { saveFileByBlob } from '@src/utils'

const row: RowInterface<ImportBandsInterface> = {
  cells: [
    {
      ...importBandsSpecialisationColumn,
      width: 100,
    },
    {
      ...importBandsSeniorityColumn,
      width: 100,
    },
    {
      ...importBandsSeniorityLevelColumn,
      width: 100,
    },
    {
      ...importBandsLocationColumn,
      width: 100,
    },
    {
      ...importBandsBenchmarkTypeColumn,
      width: 100,
    },
    {
      ...importBandsLowerBandColumn,
      width: 100,
    },
    {
      ...importBandsUpperBandColumn,
      width: 100,
    },
    {
      ...importBandsOnTargetBonusColumn,
      width: 100,
    },
    {
      ...importBandsActionColumn,
      width: 100,
    },
  ],
}

interface TableProps {
  refetchSessionData: () => Promise<
    QueryObserverResult<ImportBandsSessionInterface, Error>
  >
}

const Table = ({ refetchSessionData }: TableProps) => {
  const params = useParams<{ id: string }>()
  const permissions = useSelector(selectPermissions)
  const canImport = permissions?.includes(PermissionTypes.AddBenchmarksBulkUploadSession)

  const [exportSessionDataPending, setExportSessionDataPending] = useState(false)
  const [importPending, setImportPending] = useState(false)

  const table = useTable({
    getItems: getCompensationBandsUploadSession(params.id),
  })

  const isValid = table.metadata?.status.id === 'valid'

  const onExecuteImport = async () => {
    setImportPending(true)
    try {
      await confirmImportCompensationBands(params.id)
      await refetchSessionData()
    } finally {
      setImportPending(false)
    }
  }

  const onExportSessionData = () => {
    setExportSessionDataPending(true)
    exportCompensationBandsUploadSessionData(params.id)
      .then(response => {
        saveFileByBlob(
          response.data,
          'session_data.csv',
          response.headers['content-type'],
        )
      })
      .finally(() => setExportSessionDataPending(false))
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Total" val={table.metadata?.total_benchmarks_count} />
        <Stat label="Create" val={table.metadata?.benchmarks_to_create_count} />
        <Stat label="Update" val={table.metadata?.benchmarks_to_update_count} />
        <Stat
          label="Skipped"
          tooltip="Items skipped are ones with no changes from current band"
          val={table.metadata?.benchmarks_to_skip_count}
        />
        <Stat label="Invalid" val={table.metadata?.invalid_benchmarks_count} />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          <MoreBar.Action
            disabled={!isValid || !canImport}
            onClick={onExecuteImport}
            pending={importPending}
            useIcon="ShareIOs"
            variant="accent"
          >
            Execute import
          </MoreBar.Action>
          <MoreBar.Action
            onClick={onExportSessionData}
            pending={exportSessionDataPending}
            useIcon="Download"
          >
            Download this data
          </MoreBar.Action>
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          dataType="Bands"
          name={TableNames.ImportCompensationBands}
          row={row}
          useWindowScroll
          {...table}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export const ReviewData = () => {
  const params = useParams<{ id: string }>()
  const { data, refetch } = useGetCompensationBandsUploadSessionData(params.id)

  const backUrl = ROUTES.APPS.COMPENSATION.BANDS
  const isInProgress = data?.status.id === 'in_progress'
  const isCompleted = data?.status.id === 'completed'
  const isFailed = data?.status.id === 'failed'
  const showStatus = isInProgress || isCompleted || isFailed

  if (!data) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader backUrl={backUrl} title="Import data" subtitle="Bands" />
      {showStatus ? (
        <>
          {isInProgress && (
            <SuccessWidget
              maxWidth={720}
              text="Please wait for the data to upload, this can take several minutes. You can
 refresh this page to update the task status."
              title="Task in progress"
              type="pending"
            />
          )}
          {isCompleted && (
            <SuccessWidget
              maxWidth={720}
              text={
                <VStack gap="s-16">
                  <Text>Bands upload is completed</Text>
                  <ActionButton onClick={() => navigateTo(backUrl)} useIcon="ArrowSend">
                    Go back to bands page
                  </ActionButton>
                </VStack>
              }
              title="Upload successful"
              type="success"
            />
          )}
          {isFailed && (
            <SuccessWidget
              maxWidth={720}
              text="There was an error with the upload"
              title="Task failed"
              type="error"
            />
          )}
        </>
      ) : (
        <Table refetchSessionData={refetch} />
      )}
    </PageWrapper>
  )
}
