import React, { useState } from 'react'
import { connect } from 'lape'
import { Box, InputGroup, Text, Token, VStack, Widget } from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@src/components/Page/PageActions'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PayGroupInterface } from '@src/interfaces/payrollV2'
import RadioSwitch from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { pathToUrl } from '@src/utils/router'

import { EligibilityConfig } from './EligibilityConfig'
import {
  hasDefaultPayDateConfigSettings,
  PAY_DATE_CONF_CUSTOM,
  PAY_DATE_CONF_DEFAULT,
  PayDateConfigType,
  useCheckDefaultValues,
  useFormatNonCompliantToIdAndName,
  useFormHandlers,
} from './helpers'
import { PayrunScheduleWidget } from './PayrunScheduleWidget'

export const PayGroupEditDetails = connect(() => {
  const { values } = useLapeContext<PayGroupInterface>()

  const [customFilters, setCustomFilters] = useState<TableFilter>({})
  const [payDateConfig, setPayDateConfig] = useState<PayDateConfigType>(() =>
    !values.id || hasDefaultPayDateConfigSettings(values)
      ? PAY_DATE_CONF_DEFAULT
      : PAY_DATE_CONF_CUSTOM,
  )

  useFormatNonCompliantToIdAndName()
  useCheckDefaultValues(payDateConfig)

  const { handleSubmit, handleError } = useFormHandlers(customFilters)

  return (
    <>
      <VStack space="s-16" pb="s-72">
        <LapeNewInput name="name" label="Pay group name" required />
        <Box>
          <SectionTitle title="What payroll provider should be used for this pay group?" />
          <LapeRadioSelectInput
            name="payroll_provider"
            selector={selectorKeys.payroll_providers}
            label="Choose your payroll provider"
            required
          />
        </Box>
        <Box>
          <SectionTitle
            title="How often are your employees paid?"
            subtitle="Pay, or salary frequency, describes how often employees get paid"
          />
          <LapeRadioSelectInput
            required
            name="pay_frequency"
            label="Pay frequency"
            selector={() => Promise.resolve([{ id: 'monthly', name: 'Monthly' }])}
            searchable={false}
          />
        </Box>
        <Box>
          <SectionTitle
            title="When does your pay run start?"
            subtitle="Define which day of the month the pay run begins every month."
          />
          <LapeRadioSelectInput
            name="pay_period_start_day"
            selector={selectorKeys.month_days}
            label="Period start date (day of month)"
            required
          />
        </Box>
        <Box>
          <SectionTitle
            title="Pay date and cut off configuration"
            subtitle="Cut off dates allows you to set a deadline for change tracking for each pay run. This means there is some time between the last day of changes and when you pay employees."
          />
          <RadioSwitch<PayDateConfigType>
            variant="horizontal"
            value={payDateConfig}
            onChange={newValue => {
              setPayDateConfig(newValue)
            }}
            options={[
              {
                id: PAY_DATE_CONF_DEFAULT.id,
                label: PAY_DATE_CONF_DEFAULT.name,
                description: 'Use default values',
                value: PAY_DATE_CONF_DEFAULT,
              },
              {
                id: PAY_DATE_CONF_CUSTOM.id,
                label: PAY_DATE_CONF_CUSTOM.name,
                description: 'Configure',
                value: PAY_DATE_CONF_CUSTOM,
              },
            ]}
          />
        </Box>
        {payDateConfig.id === 'custom' && (
          <>
            <LapeRadioSelectInput
              name="pay_date_schedule"
              label="Pay date schedule"
              selector={selectorKeys.pay_date_schedules}
            />
            <Widget p="s-16">
              <VStack space="s-16">
                <Text color={Token.color.greyTone50} variant="emphasis2">
                  Cut off date (deadline for changes)
                </Text>
                <InputGroup variant="horizontal" data-testid="cut_off_date_section">
                  <Box maxWidth={320}>
                    <LapeNewInput
                      required
                      type="number"
                      name="cut_off_date_trigger_number_of_days"
                      label="Number of business days"
                    />
                  </Box>
                  <LapeRadioSelectInput
                    name="cut_off_date_trigger"
                    label="Trigger"
                    selector={selectorKeys.pay_group_triggers}
                  />
                </InputGroup>
              </VStack>
            </Widget>
            <Widget p="s-16">
              <VStack space="s-16">
                <Text color={Token.color.greyTone50} variant="emphasis2">
                  Pay date
                </Text>
                <InputGroup variant="horizontal" data-testid="cut_off_date_section">
                  <Box maxWidth={320}>
                    <LapeRadioSelectInput
                      required
                      name="pay_date_day"
                      selector={selectorKeys.month_days}
                      label="Pay date (day of month)"
                    />
                  </Box>
                  <LapeRadioSelectInput
                    name="pay_date_lands_on_weekend"
                    label="If pay date lands on weekend or holiday"
                    selector={selectorKeys.pay_date_lands_on_weekend_choices}
                  />
                </InputGroup>
              </VStack>
            </Widget>
          </>
        )}
        <PayrunScheduleWidget />
        <EligibilityConfig
          customFilters={customFilters}
          setCustomFilters={setCustomFilters}
        />
      </VStack>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            values.id
              ? 'Pay group successfully updated'
              : 'Pay group successfully created'
          }
          onAfterSubmit={res => {
            navigateReplace(
              pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW, {
                id: String(res.id),
              }),
            )
          }}
          onClick={handleSubmit}
          errorHandler={handleError}
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
})
