import { DualAvatar, Status } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'

import React from 'react'
import { HeaderActions } from './HeaderActions'

export const OneToOneSummary = () => {
  return (
    <PageWrapper>
      {/* connect api */}
      <PageHeader
        title={
          <PageHeader.Title
            avatar={
              <DualAvatar
                topImage="https://placedog.net/40/40?id=6"
                bottomImage="https://placedog.net/40/40?id=7"
              />
            }
            labels={<Status useIcon="Calendar">Friday, 10:30</Status>}
            title="1:1 Wade <> Jane"
            actions={<HeaderActions />}
          />
        }
        backUrl={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW}
      />
    </PageWrapper>
  )
}
