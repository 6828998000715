import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { useSelector } from 'react-redux'

import { Environments, env, overrideAPIMapWithTenant } from '@src/constants/api'
import { history as defaultHistory, updateHistory } from '@src/utils/router'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import { LocalStorageKeys } from '@src/store/auth/types'
import { selectAuthenticated } from '@src/store/auth/selectors'
import { navigateReplace } from '@src/actions/RouterActions'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { isWorkspacesEnabled } from '@src/utils'
import { getWorkspaceFromPathname } from './common'

// TODO: https://revolut.atlassian.net/browse/REVCOR-3210 remove after prod release
declare global {
  interface Window {
    enableWorkspaces?: () => void
  }
}

interface WorkspaceContextProps {
  workspace: string | undefined
  workspaces: string[]
  workspaceCompanyIcons: Record<string, string>
  workspaceCompanyNames: Record<string, string>
  setWorkspaceIconUrl: (url: string) => void
  setWorkspaceCompanyName: (name: string) => void
  removeWorkspace: (workspace: string) => void
}

interface WorkspaceActionState {
  workspace?: string
  action: 'redirect-to-main' | 'redirect-to-login' | 'redirect-to-workspace' | null
}

const WorkspaceContext = createContext<WorkspaceContextProps | null>(null)

export const useWorkspaceContext = () => {
  return useContext(WorkspaceContext)
}

const WorkspaceContextProvider: React.FC = ({ children }) => {
  const authenticated = useSelector(selectAuthenticated)

  const [workspaces, setWorkspaces] = useLocalStorage<string[]>(
    LocalStorageKeys.WORKSPACES,
    [],
    false,
  )

  const [workspaceCompanyIcons, setWorkspaceCompanyIcons] = useLocalStorage<
    Record<string, string>
  >(LocalStorageKeys.WORKSPACE_COMPANY_ICONS, {}, false)

  const [workspaceCompanyNames, setWorkspaceCompanyNames] = useLocalStorage<
    Record<string, string>
  >(LocalStorageKeys.WORKSPACE_COMPANY_NAMES, {}, false)

  const [{ workspace, action }] = useState<WorkspaceActionState>(() => {
    const activeWorkspace = localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE)

    const isRootPath = window.location.pathname === '/'
    const isLoginPath =
      window.location.pathname.startsWith(ROUTES.LOGIN.MAIN) &&
      window.location.pathname !== ROUTES.LOGIN.REDIRECT

    if (activeWorkspace && authenticated && (isRootPath || isLoginPath)) {
      return { workspace: activeWorkspace, action: 'redirect-to-main' }
    }
    if (activeWorkspace && !authenticated && (isRootPath || isLoginPath)) {
      return { workspace: activeWorkspace, action: 'redirect-to-login' }
    }

    if (isLoginPath) {
      return { action: 'redirect-to-workspace' }
    }

    return { workspace: getWorkspaceFromPathname(), action: null }
  })

  const history = useMemo(
    () =>
      typeof window === 'undefined'
        ? ({} as any)
        : createBrowserHistory({ basename: workspace }),
    [workspace],
  )

  const setWorkspaceIconUrl = (url: string) => {
    if (workspace) {
      setWorkspaceCompanyIcons({ ...workspaceCompanyIcons, [workspace]: url })
    }
  }

  const setWorkspaceCompanyName = (name: string) => {
    if (workspace) {
      setWorkspaceCompanyNames({ ...workspaceCompanyNames, [workspace]: name })
    }
  }

  updateHistory(history)

  const saveWorkspace = (ws: string) => {
    if (!workspaces.includes(ws)) {
      setWorkspaces([ws, ...workspaces])
    }
  }

  if (workspace) {
    localStorage.setItem(LocalStorageKeys.ACTIVE_WORKSPACE, workspace)
    saveWorkspace(workspace)
    overrideAPIMapWithTenant(workspace)
  }

  if (action === 'redirect-to-main') {
    navigateReplace(ROUTES.MAIN)
  }

  if (action === 'redirect-to-login') {
    navigateReplace(ROUTES.LOGIN.MAIN)
  }

  if (action === 'redirect-to-workspace') {
    navigateReplace(WORKSPACES.MAIN)
  }

  const removeWorkspace = (ws: string) => {
    setWorkspaces(workspaces.filter(w => w !== ws))
  }

  const value = useMemo(
    () => ({
      workspace,
      workspaces,
      removeWorkspace,
      workspaceCompanyIcons,
      workspaceCompanyNames,
      setWorkspaceIconUrl,
      setWorkspaceCompanyName,
    }),
    [
      workspace,
      workspaces,
      removeWorkspace,
      workspaceCompanyIcons,
      workspaceCompanyNames,
      setWorkspaceIconUrl,
      setWorkspaceCompanyName,
    ],
  )

  return (
    <WorkspaceContext.Provider value={value}>
      <Router history={history}>{children}</Router>
    </WorkspaceContext.Provider>
  )
}

const WorkspaceContextFeatureGuard: React.FC = ({ children }) => {
  useEffect(() => {
    // TODO: https://revolut.atlassian.net/browse/REVCOR-3210 remove after prod release
    if (env === Environments.productionCommercialRoot) {
      window.enableWorkspaces = () => {
        localStorage.setItem(LocalStorageKeys.ENABLE_DEV_WORKSPACES, 'True')
        window.location.href = '/'
      }
    }
  }, [])

  const workspacesEnabled = isWorkspacesEnabled()

  if (workspacesEnabled) {
    return <WorkspaceContextProvider>{children}</WorkspaceContextProvider>
  }

  return <Router history={defaultHistory}>{children}</Router>
}

export { WorkspaceContextFeatureGuard as WorkspaceContextProvider }
