import React from 'react'
import {
  jobPostingSettings as jobPostingSettingsRequest,
  useGetJobPostingSettings,
} from '@src/api/settings'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { FormattedMessage } from 'react-intl'
import { Avatar, Item, Switch } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const IndeedApply = () => {
  const { data: jobPostingSettings, refetch } = useGetJobPostingSettings()
  const showStatusPopup = useShowStatusPopup()
  const handleIndeedApply = async () => {
    try {
      await jobPostingSettingsRequest.update(
        {
          enable_indeed_apply: !jobPostingSettings?.enable_indeed_apply,
        },
        // this is how setting endpoints work
        { id: '1' },
      )
      showStatusPopup({
        title: jobPostingSettings?.enable_indeed_apply ? (
          <FormattedMessage
            id="integrations.indeed.settings.apply.disable.success"
            defaultMessage="Indeed apply disabled"
          />
        ) : (
          <FormattedMessage
            id="integrations.indeed.settings.apply.enable.success"
            defaultMessage="Indeed apply enabled"
          />
        ),
      })
      refetch()
    } catch (err) {
      showStatusPopup({
        description: getStringMessageFromError(err),
        status: 'error',
        title: (
          <FormattedMessage
            id="integrations.indeed.settings.apply.fail"
            defaultMessage="Indeed integration failed"
          />
        ),
      })
    }
  }
  return (
    <Item
      use="label"
      onClick={e => {
        e.preventDefault()
        handleIndeedApply()
      }}
    >
      <Item.Avatar>
        <Avatar useIcon="Cleaning" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <FormattedMessage
            id="integrations.indeed.settings.apply.title"
            defaultMessage="Enable indeed apply"
          />
        </Item.Title>
        <Item.Description>
          <FormattedMessage
            id="integrations.indeed.settings.apply.description"
            defaultMessage="Candidates would be able to apply directly through Indeed using their Indeed resume"
          />
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Switch checked={!!jobPostingSettings?.enable_indeed_apply} />
      </Item.Side>
    </Item>
  )
}
