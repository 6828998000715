import React, { useState, useMemo, useEffect, useRef } from 'react'
import { Action, Flex, TableWidget } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { useGetNotificationStats } from '@src/api/notifications'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { StatFilter, StatFilters } from '@src/components/StatFilters/StatFilters'
import {
  commonDashboardSubtabs,
  NotificationsContextProvider,
} from '@src/apps/General/Todo/common'
import { TODO_LIMIT } from '@src/pages/Forms/Notifications/GenericNotificationTable'
import Table from '@src/components/TableV2/Table'
import { useTableReturnType, useIsNewTable } from '@components/TableV2/hooks'
import {
  NotificationsInterface,
  NotificationsStatisticsInterface,
} from '@src/interfaces/notifications'
import AllNotifications from '@src/pages/Forms/Notifications/AllNotifications'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'

type DashboardSubtabComponent = typeof commonDashboardSubtabs[number]['component']

const todoStatsPaths = commonDashboardSubtabs.map(
  ({ statPath, title, component, url }) => ({
    statPath,
    title,
    component,
    url,
  }),
)
const primaryTodoTab = todoStatsPaths[0]

export const DashboardToDo = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>()

  const tableRef = useRef<useTableReturnType<
    NotificationsInterface,
    NotificationsStatisticsInterface
  > | null>(null)

  const isNewTable = useIsNewTable()
  const isCoreNavigation = useCoreNavigation()

  const selectEntities = (selection: string) => {
    setSelectedFilter(selection)

    tableRef.current?.onFilterChange({
      filters: selection === 'total' ? [] : [{ id: selection, name: selection }],
      columnName: 'category',
    })
  }

  const {
    data: stats,
    isLoading: isStatsLoading,
    refetch: refetchStats,
  } = useGetNotificationStats()

  const statFilters: (StatFilter & {
    component: DashboardSubtabComponent
    url: string
  })[] = useMemo(() => {
    if (isStatsLoading) {
      return [
        {
          id: primaryTodoTab.statPath,
          title: primaryTodoTab.title,
          value: null,
          loading: true,
          component: primaryTodoTab.component,
          url: primaryTodoTab.url,
        },
      ]
    }

    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const filteredTodoPaths = todoStatsPaths.filter(tab => Boolean(stats?.[tab.statPath]))

    if (!filteredTodoPaths.length) {
      return [
        {
          id: primaryTodoTab.statPath,
          title: primaryTodoTab.title,
          value: 0,
          loading: false,
          component: primaryTodoTab.component,
          url: primaryTodoTab.url,
        },
      ]
    }

    return filteredTodoPaths.map(tab => ({
      id: tab.statPath,
      title: tab.title,
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      value: stats?.[tab.statPath],
      component: tab.component,
      url: tab.url,
    }))
  }, [stats, isStatsLoading])

  const selectedTab = statFilters.find(f => f.id === selectedFilter)
  const DEMO_HR_CATEGORY = 'demo_hr'
  useEffect(() => {
    /** If the last todo item was cleared from a tab, stats refresh and that tab is gone. So we need to fallback to the primary tab */
    if (!selectedTab) {
      selectEntities(primaryTodoTab.statPath)
    }
    const isDemo = statFilters.some(f => f.id === DEMO_HR_CATEGORY)
    if (isDemo) {
      selectEntities(DEMO_HR_CATEGORY)
    }
  }, [selectedTab])

  const SelectedTabComponent = selectedTab?.component

  useEffect(() => {
    /** If the last todo item was cleared from a tab, stats refresh and that tab is gone. So we need to fallback to the primary tab */
    if (!SelectedTabComponent) {
      setSelectedFilter(primaryTodoTab.statPath)
    }
  }, [SelectedTabComponent])

  if (!isNewTable) {
    return (
      <TableWidget>
        <TableWidget.Info>
          <StatFilters
            selectedFilter={selectedFilter}
            filters={statFilters}
            onClick={setSelectedFilter}
          />
        </TableWidget.Info>

        <TableWidget.Table>
          {SelectedTabComponent ? (
            <NotificationsContextProvider>
              <SelectedTabComponent
                type="dashboard"
                refreshStats={() => refetchStats()}
              />
            </NotificationsContextProvider>
          ) : null}

          {!isCoreNavigation &&
          typeof selectedTab?.value === 'number' &&
          selectedTab?.value > TODO_LIMIT ? (
            <Flex justifyContent="center" mt="s-16">
              <Action use={InternalLink} to={pathToUrl(selectedTab.url)}>
                View all ({selectedTab.value})
              </Action>
            </Flex>
          ) : null}
        </TableWidget.Table>
      </TableWidget>
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatFilters
          selectedFilter={selectedFilter}
          filters={statFilters}
          onClick={selectEntities}
        />
      </Table.Widget.Info>

      <Table.Widget.Table>
        <NotificationsContextProvider>
          <AllNotifications
            type="dashboard"
            tableRef={tableRef}
            refreshStats={() => refetchStats()}
          />
        </NotificationsContextProvider>

        {!isCoreNavigation &&
        typeof selectedTab?.value === 'number' &&
        selectedTab?.value > TODO_LIMIT ? (
          <Flex justifyContent="center" mt="s-16" pb="s-16">
            <Action use={InternalLink} to={pathToUrl(selectedTab.url)}>
              View all ({selectedTab.value})
            </Action>
          </Flex>
        ) : null}
      </Table.Widget.Table>
    </Table.Widget>
  )
}
