import { IconName } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { BenefitStatus } from '@src/interfaces/rewards'

export const getBenefitStatusText = (status: IdAndName<BenefitStatus>) => {
  if (status.id === 'pending_approval') {
    return 'Pending'
  }

  if (status.id === 'pending_employee') {
    return 'Enrol now'
  }

  return status.name
}

export const getBenefitIcon = (status: BenefitStatus): IconName => {
  switch (status) {
    case 'pending_approval':
    case 'pending_employee':
      return '16/SandWatch'
    case 'opted_out':
    case 'rejected':
      return 'Cross'
    default:
      return 'Check'
  }
}

export const getCompensationTotalTitleAndTooltipText = (withOnTargetBonus: boolean) => {
  if (withOnTargetBonus) {
    return {
      title: 'Total compensation',
      tooltipText: 'Latest base salary and on target bonus for the current year.',
    }
  }

  return {
    title: 'Base salary',
    tooltipText: 'Latest base salary for the current year.',
  }
}
