import React, { useRef, useState } from 'react'
import {
  Box,
  ExpandableItem,
  Item,
  ProgressCircle,
  Skeleton,
  Text,
  Token,
  TransitionScale,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { CandidateMatchScoreTable } from '@src/features/CommonCandidatesTable/CandidateMatchScoreTable'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { useFetchCandidateScoreExplanation } from '@src/api/recruitment/interviews'

type Props = {
  opportunity: InterviewRoundInterface
}

const BRILLIANT_HIRE_SCORE_THREASHOLD = 75

export const CandidateMatchSidebarOpportunity = ({ opportunity }: Props) => {
  const nodeRef = useRef(null)
  const [expanded, setExpanded] = useState(opportunity.active)
  const { data, isLoading } = useFetchCandidateScoreExplanation(
    expanded ? opportunity.id : null,
  )

  const score = data
    ? data.degree_score.matching_score +
      data.university_score.matching_score +
      data.company_score.matching_score +
      data.job_title_score.matching_score
    : null
  const goodHire = score !== null && score >= BRILLIANT_HIRE_SCORE_THREASHOLD
  const showScore = score !== null

  const getColor = () => {
    if (!showScore) {
      return Token.color.deepGrey
    }

    return goodHire ? Token.color.success : Token.color.blue
  }

  // 0.7 and 0.05 numbers to make the ProgressCircle pretty
  let progress = 0.7
  if (showScore) {
    progress = score === 0 ? 0.05 : score / 100
  }

  return (
    <Widget>
      <Item use="button" variant="disclosure" onClick={() => setExpanded(!expanded)}>
        <Item.Avatar data-testid={`opportunity-${opportunity.id}-score`}>
          <ProgressCircle strokeWidth={3} size={44} value={progress} color={getColor()}>
            <ProgressCircle.Text style={{ fontSize: 12 }}>
              {showScore ? `${score}%` : '?'}
            </ProgressCircle.Text>
          </ProgressCircle>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{opportunity.specialisation.name}</Item.Title>
          {goodHire && (
            <ExpandableItem.Description>
              <Text variant="emphasis3" color={Token.color.success}>
                Brilliant hire 🔥
              </Text>
            </ExpandableItem.Description>
          )}
        </Item.Content>
      </Item>
      <TransitionScale nodeRef={nodeRef} in={expanded}>
        <Box ref={nodeRef}>
          {isLoading ? (
            <VStack gap="s-16" p="s-16" data-testid="table-skeleton">
              <Skeleton height={18} />
              <Skeleton height={18} />
              <Skeleton height={18} />
            </VStack>
          ) : (
            <>
              {data ? (
                <CandidateMatchScoreTable data={data} />
              ) : (
                <Box p="s-16">
                  <Text color={Token.color.greyTone50}>No archetype available yet</Text>
                </Box>
              )}
            </>
          )}
        </Box>
      </TransitionScale>
    </Widget>
  )
}
