import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import {
  HStack,
  ItemSkeleton,
  Link,
  StatusWidget,
  Subheader,
  VStack,
} from '@revolut/ui-kit'
import { BetaBadge } from '@components/BetaBadge/BetaBadge'
import sortBy from 'lodash/sortBy'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { CandidateMatchSidebarOpportunity } from '@src/features/CommonCandidatesTable/CandidateMatchSidebarOpportunity'

type Props = {
  candidateName: string
  candidateId: number
  onClose: VoidFunction
}

export const CandidateMatchSidebar = ({ onClose, candidateId, candidateName }: Props) => {
  const { data: opportunities, isLoading } = useGetFullInterviewRounds(candidateId)
  const sortedOpportunities = sortBy(
    opportunities?.filter(item => item.state !== 'archived'),
    [o => !o.active],
  )
  const specialisation = opportunities?.find(item => item.active)?.specialisation

  return (
    <SideBar
      isOpen
      onClose={onClose}
      title={candidateName}
      variant="medium"
      subtitle={
        specialisation && (
          <Link
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
              id: specialisation.id,
            })}
            target="_blank"
            color="inherit"
          >
            {specialisation.name}
          </Link>
        )
      }
    >
      <Subheader>
        <HStack gap="s-8" align="center">
          Candidate’s active opportunities <BetaBadge />
        </HStack>
      </Subheader>

      {isLoading ? (
        <VStack gap="s-16">
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </VStack>
      ) : (
        <VStack gap="s-16">
          {sortedOpportunities?.length ? (
            sortedOpportunities.map(opportunity => (
              <CandidateMatchSidebarOpportunity
                opportunity={opportunity}
                key={opportunity.id}
              />
            ))
          ) : (
            <StatusWidget>
              <StatusWidget.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images/3D086.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images/3D086@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images/3D086@3x.png',
                }}
              />
              <StatusWidget.Title>
                No active opportunities found for this candidate
              </StatusWidget.Title>
            </StatusWidget>
          )}
        </VStack>
      )}
    </SideBar>
  )
}
