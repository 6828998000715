import React from 'react'
import { Avatar, Item, Switch } from '@revolut/ui-kit'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import {
  jobPostingSettings as jobPostingSettingsRequest,
  useGetJobPostingSettings,
} from '@src/api/settings'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { FormattedMessage } from 'react-intl'

export const MonsterJobsIntegrationItem = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCareerPagePreferences)
  const { data: jobPostingSettings, refetch } = useGetJobPostingSettings()
  const showStatusPopup = useShowStatusPopup()
  return (
    <Item
      use="label"
      disabled={disableEdit}
      onClick={async e => {
        e.preventDefault()

        try {
          await jobPostingSettingsRequest.update(
            {
              enable_monster_integration: !jobPostingSettings?.enable_monster_integration,
            },
            // this is how setting endpoints work
            { id: '1' },
          )

          refetch()
          showStatusPopup({
            title: jobPostingSettings?.enable_monster_integration ? (
              <FormattedMessage
                id="recruitment.settings.job_postings.monster.successDisabled"
                defaultMessage="Monster integration disabled"
              />
            ) : (
              <FormattedMessage
                id="recruitment.settings.job_postings.monster.success"
                defaultMessage="Monster integration enabled"
              />
            ),
          })
        } catch (err) {
          showStatusPopup({
            title: (
              <FormattedMessage
                id="recruitment.settings.job_postings.monster.fail"
                defaultMessage="Monster integration failed"
              />
            ),
          })
        }
      }}
    >
      <Item.Avatar>
        <Avatar size={40} label="M" />
      </Item.Avatar>

      <Item.Content>
        <Item.Title>
          <FormattedMessage
            id="recruitment.settings.job_postings.monster.title"
            defaultMessage="Monster"
          />
        </Item.Title>
        <Item.Description>
          <FormattedMessage
            id="recruitment.settings.job_postings.monster.description"
            defaultMessage="Enable this integration to let all your published job postings appear on the Monster.com job board."
          />
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Switch checked={!!jobPostingSettings?.enable_monster_integration} />
      </Item.Side>
    </Item>
  )
}
