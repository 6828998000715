import React, { useEffect } from 'react'
import { AxiosError } from 'axios'
import pick from 'lodash/pick'
import { useSelector } from 'react-redux'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'

import { getLocationPathnameWithoutWorkspace } from '@src/actions/RouterActions'
import { dynamicGroups } from '@src/api/dynamicGroups'
import { payGroupRequests } from '@src/api/payrollV2'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { IdAndName } from '@src/interfaces'
import {
  PayGroupInterface,
  PayrunPreviewPayloadInterface,
} from '@src/interfaces/payrollV2'
import { selectUser } from '@src/store/auth/selectors'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { getFieldSetter } from '@src/utils/setDefaultField'

export const formatNonCompliantToIdAndName = (
  values: PayGroupInterface,
  toIdAndName: (id: number) => IdAndName | null,
) => {
  if (typeof values.pay_date_day === 'number' && toIdAndName(values.pay_date_day)) {
    values.pay_date_day = toIdAndName(values.pay_date_day)
  }
  if (
    typeof values.pay_period_start_day === 'number' &&
    toIdAndName(values.pay_period_start_day)
  ) {
    values.pay_period_start_day = toIdAndName(values.pay_period_start_day)
  }
}

const formatNonCompliantToId = (values: PayGroupInterface) => {
  return {
    ...(values.pay_date_day ? { pay_date_day: values.pay_date_day.id } : null),
    ...(values.pay_period_start_day
      ? { pay_period_start_day: values.pay_period_start_day.id }
      : null),
    // BE expects values in different format than what it actually returns
  } as unknown as Pick<PayGroupInterface, 'pay_date_day' | 'pay_period_start_day'>
}

export const formatValuesToSubmit = (values: PayGroupInterface) => {
  return {
    ...values,
    ...formatNonCompliantToId(values),
  }
}

const payrunScheduleFields = [
  'pay_date_day',
  'pay_frequency',
  'pay_date_schedule',
  'pay_period_start_day',
  'cut_off_date_trigger',
  'cut_off_date_trigger_number_of_days',
  'pay_date_lands_on_weekend',
]

const payrunDefaultFields = {
  number_of_schedules: 12,
  report_submission_date_trigger: {
    id: 'after_pay_cycle_starts',
    name: 'After pay cycle starts',
  },
  report_submission_date_days_after_cycle_ends: 0,
}

export const formatValuesToGetPayrunSchedule = (values: PayGroupInterface) => {
  return {
    ...pick(values, payrunScheduleFields),
    ...formatNonCompliantToId(values),
    ...payrunDefaultFields,
  } as PayrunPreviewPayloadInterface
}

export const useFormatNonCompliantToIdAndName = () => {
  const { values } = useLapeContext<PayGroupInterface>()
  const options = useGetSelectors<IdAndName>(selectorKeys.month_days)

  const toIdAndName = (value: number | null) =>
    options.data?.find(option => option.id === value) || undefined
  const formatNonCompliantIdValue = <K extends keyof PayGroupInterface>(path: K) => {
    const formatFrom = values[path]

    if (typeof formatFrom === 'number' && toIdAndName(formatFrom) !== undefined) {
      values[path] = toIdAndName(formatFrom) as PayGroupInterface[K]
    }
  }

  useEffect(() => {
    formatNonCompliantIdValue('pay_date_day')
    formatNonCompliantIdValue('pay_period_start_day')
  }, [options])
}

export const PAY_DATE_CONF_DEFAULT = { id: 'default' as const, name: 'Default' }
export const PAY_DATE_CONF_CUSTOM = { id: 'custom' as const, name: 'Custom' }
export type PayDateConfigType = typeof PAY_DATE_CONF_DEFAULT | typeof PAY_DATE_CONF_CUSTOM

// BE doesn't store config type field so this should be handled manually
export const hasDefaultPayDateConfigSettings = (values: PayGroupInterface) =>
  values.pay_date_schedule?.id === 'current_month' &&
  values.cut_off_date_trigger_number_of_days === '5' &&
  values.cut_off_date_trigger?.id === 'after_pay_cycle_starts' &&
  (values.pay_date_day?.id
    ? values.pay_date_day.id === 25
    : // @ts-expect-error: inconsistent API field should be checked for both possible values
      values.pay_date_day === 25) &&
  values.pay_date_lands_on_weekend?.id === 'pay_on_the_previous_business_day'

export const useCheckDefaultValues = (payDateConfig: PayDateConfigType) => {
  const { values } = useLapeContext<PayGroupInterface>()

  const setDefaultIfEmpty = getFieldSetter<PayGroupInterface>({
    values,
  })

  useEffect(() => {
    setDefaultIfEmpty('eligibility_type', {
      id: 'eligibility_group',
      name: 'Eligibility Group',
    })
    setDefaultIfEmpty('pay_frequency', { id: 'monthly', name: 'Monthly' })
  }, [])

  const overrideField = getFieldSetter<PayGroupInterface>({
    values,
    overrideNonEmpty: true,
  })

  useEffect(() => {
    if (payDateConfig.id === 'default') {
      overrideField('pay_date_schedule', { id: 'current_month', name: 'Current Month' })
      overrideField('cut_off_date_trigger_number_of_days', '5')
      overrideField('cut_off_date_trigger', {
        id: 'after_pay_cycle_starts',
        name: 'After the pay cycle starts',
      })
      overrideField('pay_date_day', { id: 25, name: '25th' })
      overrideField('pay_date_lands_on_weekend', {
        id: 'pay_on_the_previous_business_day',
        name: 'Pay on the previous business day',
      })
    }
  }, [payDateConfig.id])
}

export const useFormHandlers = (customFilters: TableFilter | null) => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<PayGroupInterface>()
  const statusPopup = useStatusPopup()

  const createOrUpdateEligibilityGroup = async () => {
    if (values.eligibility_type?.id === 'employee_filters') {
      if (values.eligibility_group?.id) {
        await dynamicGroups.update(
          { table_filters: customFilters },
          { id: values.eligibility_group.id },
        )
      } else {
        const newGroup = await dynamicGroups.submit(
          {
            name: `Created automatically for ${values.name} ${
              values.id || Math.floor(Math.random() * 1000000)
            } pay group`,
            owner: { id: user.id, name: user.full_name },
            visibility: { id: 'nobody', name: 'Nobody' },
            audience_selection_type: {
              id: 'filtered_table',
              name: 'Table with filters',
            },
            table_filters: customFilters,
          },
          {},
        )
        values.eligibility_group = {
          id: String(newGroup.data.id),
          name: newGroup.data.name,
        }
      }
    }
  }

  const handleSubmit = async () => {
    await createOrUpdateEligibilityGroup()

    const url = getLocationPathnameWithoutWorkspace() + window.location.search
    workspaceLocalStorage.removeItem(url)

    if (values.id) {
      const res = await payGroupRequests.update(formatValuesToSubmit(values), {
        id: String(values.id),
      })
      return res?.data
    }
    const res = await payGroupRequests.submit(formatValuesToSubmit(values), {})
    return res?.data
  }

  const handleError = (err: AxiosError) => {
    if (err.response?.status === 400) {
      throw err
    }
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Something went wrong</StatusPopup.Title>
        <StatusPopup.Description>
          {getStringMessageFromError(err)}
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button elevated onClick={() => statusPopup.hide()}>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return { handleSubmit, handleError }
}
