import React from 'react'
import {
  Action,
  Avatar,
  Flex,
  Icon,
  Item,
  HStack,
  Text,
  Token,
  Widget,
} from '@revolut/ui-kit'
import { useGetEmployeeTotalCompensationStats } from '@src/api/totalCompensation'
import { ROUTES } from '@src/constants/routes'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { formatMoney } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { PieChart } from '../Charts/PieChart'
import { getCompensationTotalTitleAndTooltipText } from './helpers'

export const MIN_HEIGHT = 520

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const CompensationTotal = ({ compensationParams, id }: Props) => {
  const { currency, setCurrency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeTotalCompensationStats(id, filters)

  if (!isLoading && !data) {
    return null
  }

  const pieChartData = [
    {
      name: 'Base salary',
      value: data ? Number(data.current_year_base_salary) : 0,
      color: Token.colorChannel.blue,
    },
    // TODO: temporarily hidden, uncomment after bonuses upload is done https://revolut.atlassian.net/browse/RHR-6163
    // {
    //     name: '*On target bonus',
    //     value: data ? Number(data.on_target_cash_bonus) : 0,
    //     color: Token.colorChannel.purple,
    //     isProjected: true,
    //   },
  ].filter(({ value }) => !!value)

  // const withOnTargetBonus = !!Number(data?.on_target_cash_bonus)
  // const total = data
  //   ? Number(data.current_year_base_salary) + Number(data.on_target_cash_bonus)
  //   : undefined

  const { title, tooltipText } = getCompensationTotalTitleAndTooltipText(false)

  return (
    <Flex
      alignItems="stretch"
      flexDirection="column"
      height="55vh"
      minHeight={MIN_HEIGHT}
      gap="s-16"
      data-testid="total_compensation_section"
    >
      <Widget flex={1} p="s-16">
        <Flex alignItems="stretch" flexDirection="column" height="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <HStack gap="s-4">
              <Text variant="emphasis2">{title}</Text>
              <Tooltip placement="bottom" text={tooltipText}>
                <Icon name="InfoOutline" size={16} />
              </Tooltip>
            </HStack>
            <Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.SALARY, { id: String(id) })}
              use={InternalLink}
            >
              Detail view
            </Action>
          </Flex>

          <CurrencySelect
            label=""
            onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
            value={data?.currency.iso_code}
          />

          <PieChart
            currency={data?.currency.iso_code}
            data={pieChartData}
            isLoading={isLoading}
            hideLegend
            total={data ? Number(data.current_year_base_salary) : undefined}
          />
        </Flex>
      </Widget>

      {data && !!Number(data.total_equity) && (
        <Widget>
          <Flex alignItems="center" justifyContent="space-between" pt="s-16" px="s-16">
            <HStack gap="s-4">
              <Text variant="emphasis2">Equity</Text>
              <Tooltip
                placement="bottom"
                text="The total value of your equity, including vested and unvested shares based on the latest share price valuation, and sold shares based on sell price."
              >
                <Icon name="InfoOutline" size={16} />
              </Tooltip>
            </HStack>
            <Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.EQUITY, { id: String(id) })}
              use={InternalLink}
            >
              Detail view
            </Action>
          </Flex>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Performance" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Total equity</Item.Title>
            </Item.Content>
            <Item.Side>
              {formatMoney(Number(data.total_equity), data.currency.iso_code)}
            </Item.Side>
          </Item>
        </Widget>
      )}
      {data && !!Number(data.total_cash_bonus) && (
        <Widget>
          <Flex alignItems="center" justifyContent="space-between" pt="s-16" px="s-16">
            <HStack gap="s-4">
              <Text variant="emphasis2">Cash bonus</Text>
              <Tooltip placement="bottom" text="All cash bonuses awarded.">
                <Icon name="InfoOutline" size={16} />
              </Tooltip>
            </HStack>
            <Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS_V2.BONUSES, { id: String(id) })}
              use={InternalLink}
            >
              Detail view
            </Action>
          </Flex>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="CreditBag" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>All time cash bonus</Item.Title>
            </Item.Content>
            <Item.Side>
              {formatMoney(Number(data.total_cash_bonus), data.currency.iso_code)}
            </Item.Side>
          </Item>
        </Widget>
      )}
    </Flex>
  )
}
