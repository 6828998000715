import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import { SeniorityInterface } from '@src/interfaces/seniority'
import {
  EmployeeBasicInterface,
  EmployeeInterface,
  EmployeeOptionInterface,
} from '@src/interfaces/employees'
import { Currency, OptionInterface } from '@src/interfaces/selectors'
import {
  CriteriaAssessment,
  PerformanceRating,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { InterviewScorecardType } from '@src/interfaces/interviewScorecardTemplates'
import { PerformanceScores } from '@src/interfaces/scorecard'
import {
  OnlineTestInterface,
  OnlineTestResultInterface,
} from '@src/interfaces/hiringProccess'
import { FileInterface } from '@src/interfaces/files'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import { RequisitionSelectorInterface } from '@src/interfaces/requisitions'
import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import {
  CareersApplicationEducationInterface,
  CareersApplicationWorkInterface,
} from '@src/interfaces/careers'
import { SigningStatuses } from '@src/interfaces/offerCreation'

export type DurationUnitType = 'minutes' | 'hours' | 'days'

export enum InterviewResult {
  Hire = 'hire',
  NoHire = 'no_hire',
  StrongHire = 'strong_hire',
  StrongNoHire = 'strong_no_hire',
}

export const InterviewResultToTitle = {
  [InterviewResult.Hire]: 'Hire',
  [InterviewResult.NoHire]: 'No Hire',
  [InterviewResult.StrongHire]: 'Strong Hire',
  [InterviewResult.StrongNoHire]: 'Strong No Hire',
}

export interface InterviewSpecialisationInterface {
  id: number
  name: string
  status: Statuses
}

export interface InterviewRoleInterface {
  id: number
  name: string
  status: Statuses
}

export interface InterviewRecruiterInterface {
  id: number
  full_name: string
  display_name: string
}

export enum InterviewRoundState {
  active = 'active',
  hired = 'hired',
  archived = 'archived',
}

export type UTMSource = 'linkedin' | string

export interface ApplicationUTMDataInterface {
  date?: string
  new_application_flow?: boolean
  utm_campaign?: string
  utm_source?: UTMSource
}

type ApplicationFormOnly = {
  id: number
  job_posting: IdAndName | null
}

export interface InterviewRoundInterface {
  id: number
  candidate: CandidateInterface
  opportunity_id: string
  recruiter?: InterviewRecruiterInterface
  coordinator?: InterviewRecruiterInterface
  hiring_manager?: EmployeeOptionInterface | null
  created_by?: EmployeeOptionInterface
  origin?: OriginType
  role: InterviewRoleInterface
  seniority: SeniorityInterface | null
  specialisation: InterviewSpecialisationInterface
  active: boolean
  latest_interview_stage?: LatestInterviewStageInterface
  reference_check_completed_amount?: number
  reference_check_minimum_required_amount?: number
  archived_comment?: string
  archived_reason?: OptionInterface | null
  archived_date_time?: string | null
  archived_employee?: EmployeeOptionInterface | null
  stage_count?: number
  state: InterviewRoundState
  field_options?: FieldOptions
  application?: ApplicationFormOnly
  application_forms?: ApplicationFormOnly[]
  latest_interview_stage_updated_date_time?: string
  requisition?: RequisitionSelectorInterface
  preferred_location?: IdAndName & { location_name: string }
  local_currency?: Currency
  local_expected_salary?: number
  conversion_currency?: Currency
  converted_expected_salary?: number
  candidate_declined_to_disclose_expected_salary?: boolean
  additional_information_about_expected_salary?: string
  cv_seen_at?: string
  cv_seen_by?: EmployeeOptionInterface
  specialisation_latest_application_date_time?: string
  application_utm_data?: ApplicationUTMDataInterface
  candidate_score: number | null
}

interface InterviewSeniorityInterface extends SeniorityInterface {
  suggested_grade: PerformanceScores
}

export interface InterviewRoundSummaryInterface {
  seniorities: InterviewSeniorityInterface[]
  skill_ratings: {
    delivery: InterviewRoundSummaryRatingInterface[]
    skill: InterviewRoundSummaryRatingInterface[]
    value: InterviewRoundSummaryRatingInterface[]
  }
  skill_ratings_normalized: InterviewRoundSummaryRatingNormalizedInterface[]
  suggested_grade?: PerformanceScores
  suggested_seniority?: SeniorityInterface
}

export interface PerformanceHiringRoundSummaryRatingInterface {
  average_rating?: PerformanceRating | null
  skills?: InterviewRoundSummaryRatingInterface[]
}

export interface PerformanceHiringRoundSummaryInterface {
  all_interviewers?: InterviewToolInterviewer[]
  skill_ratings: {
    average_rating?: PerformanceRating
    delivery: PerformanceHiringRoundSummaryRatingInterface
    skill: PerformanceHiringRoundSummaryRatingInterface
    value: PerformanceHiringRoundSummaryRatingInterface
  }
  suggested_seniority?: SeniorityInterface
}

export interface InterviewRoundSummaryRatingNormalizedInterface {
  name: string
  children: InterviewRoundSummaryRatingInterface[]
}

export type InterviewRoundRatingExpectation = 'above' | 'below' | 'equal'

export interface InterviewRoundSummaryRatingInterface {
  average_rating: PerformanceRating
  competencies?: {
    competency_level: PerformanceRating
    seniority_id: number
    seniority_level: number
    seniority_name: string
  }[]
  id: number
  name: string
  interviewer_ratings: InterviewRoundSummaryInterviewRatingInterface[]
  comparison_to_expected?: InterviewRoundRatingExpectation
  expected_competency?: PerformanceRating
}

export interface InterviewRoundSummaryInterviewRatingInterface {
  rating: PerformanceRating
  interviewer: InterviewToolInterviewer
  interview_feedback_ids: number[]
  interview_types?: InterviewType[]
  extra_fields?: {
    interview_stage_id: number
    is_passed?: boolean
    percentage_score?: number
    score?: number
  }[]
}

export interface InterviewToolInterviewer
  extends Pick<EmployeeOptionInterface, 'display_name' | 'full_name' | 'id' | 'avatar'> {}

export type InterviewType =
  | 'Total'
  | 'cv_screening'
  | 'screen_call'
  | 'home_assessment'
  | 'skills_round'
  | 'online_test'
  | 'test_review'
  | 'final_round'
  | 'hiring_panel'
  | 'offer'
  | 'prep_call'

type InterviewAutomationStatus =
  | 'not_started'
  | 'in_progress'
  | 'completed'
  | 'failed'
  | null

export interface InterviewStageInterface {
  id: number
  reference_code: string
  title: string
  interview_round: InterviewRoundInterface
  interview_number: number
  interview_number_for_type?: number
  duration: number
  duration_unit: DurationUnitType
  interview_type: InterviewType
  interview_feedbacks: InterviewFeedbackInterface[]
  is_stage_feedback_editable: boolean
  seniority_min: SeniorityInterface | null
  seniority_max: SeniorityInterface | null
  is_enabled: boolean
  scheduling_status: InterviewStatuses
  scheduling_status_display: string
  skip_scheduling?: boolean
  average_rating?: PerformanceRating
  online_test?: OnlineTestInterface
  online_test_result?: OnlineTestResultInterface
  test_platform?: string
  interview_type_display?: string
  hiring_stage_id: number
  automation_status?: InterviewAutomationStatus
  automation_disabled?: boolean
  created_date_time?: string
  updated_date_time?: string
}

export interface InterviewStageWithoutRoundInterface
  extends Omit<InterviewStageInterface, 'interview_round'> {}

export type StagesWidgetOnClickHandler = (
  data: InterviewStageWithoutRoundInterface | InterviewFeedbackInterface,
  mode?: ScheduleSidebarModeType,
  stageType?: InterviewType,
  actionType?: CandidateSidebarTypes,
) => void

export type StagesWidgetRowOptions = {
  round: InterviewRoundInterface
  candidate: CandidateInterface
  stages: InterviewStageWithoutRoundInterface[]
  canAddFeedback: boolean
  canCancel: boolean
  canViewEditOffer: boolean
  disableActions: boolean
  onRefresh: () => void
  onClick?: StagesWidgetOnClickHandler
}

export interface LatestInterviewStageInterface
  extends Pick<
    InterviewStageInterface,
    | 'id'
    | 'interview_number'
    | 'interview_number_for_type'
    | 'interview_type'
    | 'reference_code'
    | 'scheduling_status'
    | 'scheduling_status_display'
    | 'title'
    | 'interview_type_display'
  > {}

export enum InterviewFeedbackStatus {
  pending = 'pending',
  draft = 'draft',
  completed = 'completed',
  rejected = 'rejected',
}

export interface InterviewFeedbackInterface {
  id: number
  interview_stage: InterviewStageInterface
  scheduled_date_time: string | null
  updated_date_time: string
  created_date_time: string
  status: InterviewFeedbackStatus
  interviewer?: InterviewToolInterviewer
  result?: InterviewResult | null | ''
  recommended_result: InterviewResult | null
  scorecard: InterviewFeedbackDataInterface
  notes_for_candidate: string
  overall_impressions: string
  deviation_justification: string
  points?: number
  maximum_points: number | null
  score?: number | null
  pass_score: number | null
  evaluated_seniority?: SeniorityInterface
  recommended_seniority?: SeniorityInterface
  field_options: FieldOptions
  is_feedback_editable?: boolean
  rating?: PerformanceRating
  stage_type?: IdAndName<InterviewType>
  interview_type?: InterviewType
  current_country?: OptionInterface | null
  preferred_location?: OptionInterface | null
  current_salary_amount?: number | null
  expected_salary_amount?: number | null
  current_salary_currency?: OptionInterface | null
  expected_salary_currency?: OptionInterface | null
  current_salary_frequency?: OptionInterface | null
  expected_salary_frequency?: OptionInterface | null
  additional_information_about_current_salary?: string | null
  additional_information_about_expected_salary?: string | null
  candidate_declined_to_disclose_current_salary?: boolean | null
  candidate_declined_to_disclose_expected_salary?: boolean | null
  right_to_work?: string | number
  is_eligible_to_relocate?: boolean | null
  is_ready_to_relocate?: boolean | null
  playbook_link?: string | null
}

export interface InterviewFeedbackStats {
  total: number
  status: {
    draft: number
    pending: number
    completed: number
    rejected: number
  }
}

export interface InterviewFeedbackSummaryInterface {
  points?: number
  expected_level?: PerformanceRating
  suggested_level?: PerformanceRating
  skill_ratings?: InterviewSkillRatingInterface[]
  suggested_seniority?: SeniorityInterface
  hire_recommendation?: InterviewResult
  deviation_justification_required: boolean
}

export interface InterviewSkillRatingInterface {
  id: number
  name: string
  rating: PerformanceRating
}

export interface InterviewFeedbackBaseSectionInterface {
  title: string
  internal_data_id: string
  sub_title?: string
  optional?: boolean
}

export interface InterviewQuestionInterface {
  id?: string
  internal_data_id: string
  question: string
  answer: boolean
  points: number
}

export enum ThumbsOptions {
  Yes = 'yes',
  YesWithHelp = 'yes_with_help',
  No = 'no',
}

export interface InterviewThumbsQuestionInterface {
  question: string
  answer?: ThumbsOptions
  points: number
}

export interface InterviewOptionInterface {
  id?: string
  internal_data_id: string
  option: string
  points: number
}

interface InterviewQuestion {
  question: string
  answer: string
  internal_data_id: string
  internal_data_type: string
}

interface InterviewLevelQuestion {
  level: string
  internal_data_id?: string
  internal_data_type?: string
  questions: InterviewQuestion[]
}

interface InterviewFeedbackThumbsSectionInterface
  extends InterviewFeedbackBaseSectionInterface {
  internal_data_type: 'thumbs_questions_interview_section'
  questions: InterviewThumbsQuestionInterface[]
}

interface InterviewFeedbackOptionSectionInterface
  extends InterviewFeedbackBaseSectionInterface {
  internal_data_type: 'options_question_interview_section'
  options: InterviewOptionInterface[]
  answer?: InterviewOptionInterface
}

export interface InterviewFeedbackDropdownSectionInterface
  extends InterviewFeedbackBaseSectionInterface {
  internal_data_type: 'dropdown_question_interview_section'
  options: InterviewOptionInterface[]
  answer?: InterviewOptionInterface
  placeholder?: string
}

interface InterviewFeedbackQuestionSectionInterface
  extends InterviewFeedbackBaseSectionInterface {
  internal_data_type: 'checkbox_questions_interview_section'
  questions: InterviewQuestionInterface[]
  answer?: InterviewQuestionInterface[]
}

interface InterviewFeedbackTextSectionInterface
  extends InterviewFeedbackBaseSectionInterface {
  internal_data_type: 'text_question_interview_section'
  answer?: string
  placeholder: string
}

export interface InterviewFeedbackSkillSectionInterface
  extends InterviewFeedbackBaseSectionInterface {
  internal_data_type: 'skill_interview_section'
  title: string
  name?: string
  sections?: CriteriaAssessment[]
  notes?: string
  levels_questions?: InterviewLevelQuestion[]
  skills?: OptionInterface[]
}

export interface InterviewFeedbackSeniorityOptionInterface {
  id?: string
  internal_data_id: string
  points: number | null
  seniority: SeniorityInterface
}

export interface InterviewFeedbackSenioritySectionInterface
  extends InterviewFeedbackBaseSectionInterface {
  internal_data_type: 'seniority_question_interview_section'
  options: InterviewFeedbackSeniorityOptionInterface[]
  answer?: { seniority: SeniorityInterface }
}

export interface InterviewReviewScorecardInterface extends ReviewScorecardInterface {
  scorecard?: InterviewFeedbackDataInterface
}

export type InterviewFeedbackSection =
  | InterviewFeedbackOptionSectionInterface
  | InterviewFeedbackDropdownSectionInterface
  | InterviewFeedbackQuestionSectionInterface
  | InterviewFeedbackTextSectionInterface
  | InterviewFeedbackThumbsSectionInterface
  | InterviewFeedbackSkillSectionInterface

export interface InterviewFeedbackLevelsPoints {
  advanced_points?: number | null
  basic_points?: number | null
  expert_points?: number | null
  intermediate_points?: number | null
  internal_data_id: string
  internal_data_type: 'levels_points_interview_section'
}

export interface InterviewFeedbackDataInterface {
  internal_data_id: string
  interview_name?: string
  interview_objective?: string
  sections?: InterviewFeedbackSection[]
  seniority_section?: InterviewFeedbackSenioritySectionInterface
  levels_points?: InterviewFeedbackLevelsPoints
  scorecard_type?: InterviewScorecardType
}

export interface ExistingCandidatesParamsInterface {
  full_name?: string
  email?: string
  phone?: string
  linkedin_url?: string
}

export type OriginType =
  | 'application'
  | 'referral'
  | 'sourcing'
  | 'internal'
  | 'booking_link'

export enum ReEngagementStatuses {
  not_engaged = 'not_engaged',
  email_sent = 'email_sent',
  replied_to_email = 'replied_to_email',
  call_scheduled = 'call_scheduled',
}

export interface SnoozeCandidateInterface {
  id?: string
  // candidate ids only used in bulk
  candidate_ids?: number[]
  comment?: string
  creation_date_time?: string
  notify_employees?: EmployeeInterface[]
  snooze_until: string
  snoozed_by?: EmployeeInterface
}

interface CountryInterface extends IdAndName {
  country_code: string
}

export interface CandidateInterface {
  id: number
  full_name: string
  email?: string
  links?: string[]
  phone?: string
  source?: string
  origin?: OriginType
  active_interview_round?: InterviewRoundInterface
  candidate_owner?: InterviewRecruiterInterface
  recruiter?: InterviewRecruiterInterface
  inactivity_short_message?: string
  inactivity_long_message?: string
  inactivity_reason?: string
  latest_activity_date_time?: string | null
  field_options?: FieldOptions
  location?: string
  country?: CountryInterface
  headline?: string
  is_confidential?: boolean
  is_anonymised?: boolean
  created_by?: EmployeeOptionInterface
  anonymising_expected_date_time?: string
  latest_interviewer?: EmployeeOptionInterface
  latest_event_date_time?: string
  matching_fields?: Array<keyof ExistingCandidatesParamsInterface>
  employee_id?: number
  local_current_salary?: number
  local_currency?: Currency
  candidate_declined_to_disclose_current_salary?: boolean
  additional_information_about_current_salary?: string
  current_position?: string
  years_of_experience: number | null
  right_to_work?: string | number
  is_eligible_to_relocate?: boolean | null
  is_ready_to_relocate?: boolean | null
  can_re_engage: boolean | null
  re_engagement_date_time: string | null
  re_engagement_status?: ReEngagementStatuses
  educations?: CareersApplicationEducationInterface[]
  work_experiences?: CareersApplicationWorkInterface[]
  active_snoozing?: SnoozeCandidateInterface
  tags?: OptionInterface[]
  preferred_locations?: CountryInterface[]
}

export interface CandidatesListInterface
  extends Pick<
    CandidateInterface,
    | 'id'
    | 'full_name'
    | 'active_interview_round'
    | 'origin'
    | 'recruiter'
    | 'latest_interviewer'
    | 'latest_event_date_time'
    | 'latest_activity_date_time'
    | 'work_experiences'
    | 'years_of_experience'
    | 'tags'
    | 'active_snoozing'
    | 'headline'
  > {
  last_offer_sent: {
    id: number
    job_title: string
    team: IdAndName
    location: IdAndName
    status: Statuses
    is_reapproval: boolean
    signing_status: SigningStatuses
    sent_date: string
    anticipated_start_date: string
    signed_date: string | null
    requisition_id: number
  } | null
}

export interface InterviewCandidateStageOptionInterface extends OptionInterface {
  specialisation_name: string
}

export interface AvailableInterviewerSlot {
  id: string
  created_date: string
  description: string
  employee: EmployeeOptionInterface
  event_end_datetime: string
  event_start_datetime: string
  google_calendar_id: string
  status: Statuses
  summary: string
  updated_date: string
}

export interface DayPotentialSlotGroupInterface {
  id: string
  label: string
  items: PotentialInterviewerSlots[]
}

export interface PotentialInterviewerSlots {
  slot_start: string
  slot_end: string
  free_interviewers: EmployeeOptionInterface[]
}

export interface AvailableCandidateSlots {
  id: string
  event_end_datetime: string
  event_start_datetime: string
}

export interface SchedulingEmailPreviewInterface
  extends Pick<
    EmailTemplateInterface,
    'sender_type' | 'subject' | 'recipients_cc' | 'recipients_bcc' | 'attachments'
  > {
  html: string
  send_to: string
}

export interface CandidateInterviewStageInterface
  extends Pick<
    InterviewStageInterface,
    | 'id'
    | 'duration'
    | 'duration_unit'
    | 'title'
    | 'scheduling_status'
    | 'scheduling_status_display'
  > {}

export enum InterviewStatuses {
  not_started = 'not_started',
  pending_scheduling = 'pending_scheduling',
  pending_candidate_response = 'pending_candidate_response',
  awaiting_interviewer_availability = 'awaiting_interviewer_availability',
  interview_scheduled = 'interview_scheduled',
  awaiting_feedback = 'awaiting_feedback',
  feedback_submitted = 'feedback_submitted',
  scheduling_expired = 'scheduling_expired',
  interviewer_rejected = 'interviewer_rejected',
  pending_cv_screening = 'pending_cv_screening',
  interview_cancelled = 'interview_cancelled',
  test_not_sent = 'test_not_sent',
  test_sent = 'test_sent',
  test_completed = 'test_completed',
  offer_not_sent = 'offer_not_sent',
  pending_candidate_signature = 'pending_candidate_signature',
  offer_signed = 'offer_signed',
  offer_expired = 'offer_expired',
  offer_declined_recruiter = 'offer_declined_recruiter',
  offer_declined_candidate = 'offer_declined_candidate',
  offer_cancelled_recruiter = 'offer_cancelled_recruiter',
  cv_seen = 'cv_seen',
  pending_hiring_panel = 'pending_hiring_panel',
  hired = 'hired',
  interview_passed = 'interview_passed',
}

export const InterviewPendingSchedulingTypes: InterviewType[] = [
  'screen_call',
  'home_assessment',
  'skills_round',
  'final_round',
  'test_review',
  'prep_call',
]

export const InterviewPendingSchedulingStatuses = [
  InterviewStatuses.not_started,
  InterviewStatuses.pending_scheduling,
]

export const InterviewShowScheduleSidebarStatuses = [
  InterviewStatuses.pending_scheduling,
  InterviewStatuses.pending_candidate_response,
  InterviewStatuses.awaiting_interviewer_availability,
  InterviewStatuses.scheduling_expired,
  InterviewStatuses.interviewer_rejected,
  InterviewStatuses.interview_cancelled,
  InterviewStatuses.interview_scheduled,
]

export const InterviewReschedulingStatuses = [
  InterviewStatuses.pending_candidate_response,
  InterviewStatuses.awaiting_interviewer_availability,
  InterviewStatuses.scheduling_expired,
  InterviewStatuses.interviewer_rejected,
  InterviewStatuses.interview_scheduled,
  InterviewStatuses.interview_cancelled,
  InterviewStatuses.awaiting_feedback,
  InterviewStatuses.feedback_submitted,
  InterviewStatuses.interview_passed,
]

export const InterviewCancellingStatuses = [
  InterviewStatuses.pending_candidate_response,
  InterviewStatuses.awaiting_interviewer_availability,
  InterviewStatuses.scheduling_expired,
  InterviewStatuses.interviewer_rejected,
  InterviewStatuses.interview_scheduled,
  InterviewStatuses.interview_passed,
]

export interface SchedulingInterviewInterface {
  id?: number
  interview_stage: CandidateInterviewStageInterface
  scheduling_timezone: IdAndName<string>
  candidate_timezone?: { id: string }
  proposed_event_ids?: string[]
  recruiter?: {
    email: string
    full_name: string
  }
  chosen_event_id?: string
  candidate?: {
    full_name: string
  }
  email_content?: string
  scheduling_type: SchedulingType
  interviewer?: EmployeeOptionInterface
  additional_interviewers?: EmployeeOptionInterface[]
  event_date_time?: string
  is_candidate_involved?: boolean
  duration?: number
  duration_unit?: DurationUnitType
  is_adhoc?: boolean
  adhoc_scheduling_status?: InterviewStatuses
  summary?: string
}

export enum SchedulingType {
  classic = 'classic_scheduling',
  custom = 'custom_scheduling',
}

export interface SendInviteInterface
  extends Pick<
    SchedulingInterviewInterface,
    | 'proposed_event_ids'
    | 'email_content'
    | 'scheduling_type'
    | 'interviewer'
    | 'additional_interviewers'
    | 'event_date_time'
    | 'duration'
  > {
  interview_stage: { id: number }
  scheduling_timezone: { id: string }
  is_candidate_involved?: boolean
  attachments: (FileInterface | File)[] | null
  duration_unit?: { id: DurationUnitType }
}

export interface SchedulingCandidateInterface {
  id: number
  interview_stage: CandidateInterviewStageInterface
  candidate_timezone: { id: string; name?: string; offset?: string }
  chosen_event_id: string
  candidate: {
    full_name: string
  }
  email_content: string
  coordinator?: {
    email?: string
  }
  duration?: number
  duration_unit?: DurationUnitType
  is_adhoc?: boolean
}

export type ScheduleSidebarModeType =
  | 'view'
  | 'preview'
  | 'editing'
  | 'scheduling'
  | 'rescheduling'
  | 'email'

export interface CandidateCVInterface {
  id: number
  resume: {
    id: number
    name: string
    url: string
    type: string
    content_type: string
    extension: string
  }
}

export type CandidateSidebarTypes =
  | 'candidate'
  | 'schedule'
  | 'stage'
  | 'cv'
  | 'personalData'
  | 'shortSummary'
  | 'onlineTestSummary'
  | 'rounds'
  | 'sendEmail'
  | 'archive'
  | 'sendOnlineTest'
  | 'sendEngagementEmail'

export type CandidateActiveActionType = {
  type: CandidateSidebarTypes
  mode?: ScheduleSidebarModeType
  isPrepCall?: boolean
  interviewId?: number
  stageId?: number
}

export type CandidateDocumentCategory =
  | 'candidate_resume'
  | 'online_test_report'
  | 'candidate_other'

export interface CandidateAddDocumentInterface {
  id?: number
  name?: string
  file?: File
  category: IdAndName<CandidateDocumentCategory>
}

export interface SchedulingCalendarInterface {
  id: number
  calendar_id: string
  employee: EmployeeBasicInterface
  summary: string
}

export interface AddInterviewRoundInterface {
  id?: number
  job_posting?: JobPostingLocationTypeInterface
  created_by: OptionInterface
}

export interface BulkEditCandidateInterface {
  candidate_ids: (string | number)[]
  edit_fields: {
    recruiter: number
  }
}

export interface BulkMoveToStageInterface {
  candidate_ids: (string | number)[]
  stage_hiring_stage_id?: number | string
  stage_hiring_stage_type?: Extract<InterviewType, 'hiring_panel' | 'offer'>
}

export interface BulkSendOnlineTestInterface {
  candidate_ids: (string | number)[]
  test_id: number
}

export interface CandidateFreezingPeriodInterface {
  archived_date_time: string | null
  freezing_period_months: number | null
}

type HireVueInterviewType =
  | 'OnDemandVideo'
  | 'OnDemandVoice'
  | 'OnDemandText'
  | 'InPerson'
  | 'LivePhone'
  | 'LiveVideo'

export type HireVueEventType =
  | {
      AnswerScore: number
      Band: string
      ExternalIdentifier: string
      ExpirationDate: string
      InterviewId: number
      InterviewType: 'Video' | 'Voice' | 'SMS'
      RatingAverage: number
      RatingVotes: number
      RatingPassVotes: number
      RatingFailVotes: number
      ReviewUrl: string
      Score: number
      StartedDate: string
      SubmittedDate: string
      Status: 'Canceled' | 'Expired' | 'Invited' | 'Started' | 'Submitted'
      StatusDate: string
    }
  | {
      Band: string
      Complete: boolean
      CurrentStage: string
      InterviewId: number
      InterviewType: HireVueInterviewType
      ExternalIdentifier: string
      NextStep: HireVueInterviewType | null
      Score: number
      Status: 'WorkflowPassed' | 'WorkflowFailed' | 'WorkflowCompleted'
      StatusDate: string
    }

export interface OnlineTestResultEventInterface {
  id: number
  interviewId: number
  band?: string
  score?: number
  reviewUrl?: string
  interviewStatus?: 'Canceled' | 'Expired' | 'Invited' | 'Started' | 'Submitted'
  workflowStatus?: 'WorkflowPassed' | 'WorkflowFailed' | 'WorkflowCompleted'
  startedDate?: string
  submittedDate?: string
}

export interface OnlineTestResultStageInterface {
  interviewId: number
  currentStage?: string
  events: OnlineTestResultEventInterface[]
}

export interface OnlineTestPreviewInterface
  extends Pick<InterviewStageInterface, 'online_test' | 'test_platform'> {}

export interface InterviewerInterviewsCountInterface {
  n_interviews_for_period: number
  period_end_datetime: string
  period_id: string
  period_start_datetime: string
  period_type: 'day' | 'week'
}

export interface InterviewerAvailabilityLimitsInterface {
  max_interviews_per_day: number | null
  max_interviews_per_week: number | null
}

export interface EligibleInterviewerInterface
  extends Pick<EmployeeOptionInterface, 'id' | 'avatar' | 'full_name' | 'name'> {
  interviewer_interview_counts: InterviewerInterviewsCountInterface[]
  employee_interviews_availability_limits: InterviewerAvailabilityLimitsInterface | null
}

export type SchedulingMode = 'calendar' | 'slots' | 'default'

interface CandidateScoringExplanationItemInterface {
  archetype_values: string[]
  candidate_values: string[]
  matching_score: number
  matching_values: string[]
}

export interface CandidateScoringExplanationInterface {
  company_score: CandidateScoringExplanationItemInterface
  degree_score: CandidateScoringExplanationItemInterface
  job_title_score: CandidateScoringExplanationItemInterface
  university_score: CandidateScoringExplanationItemInterface
}

export interface CandidateScoringExplanationTableInterface {
  score: number
  type: string
  matches: boolean
}
