import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  InputGroup,
  Input,
  Subheader,
  Popup,
  Header,
  Text,
  Button,
  IconButton,
  Color,
  MoreBar,
} from '@revolut/ui-kit'
import { upperFirst } from 'lodash'

import { selectorKeys } from '@src/constants/api'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import {
  EmployeeContractsInterface,
  EmployeeInterface,
  EmployeeContractType,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
} from '@src/interfaces/employees'
import { employeesRequests, getEmployeePaygroup } from '@src/api/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import EmployeeDocSectionLape from '@src/features/FormTabs/Employee/EmployeeDocSectionLape'
import { useLape } from 'lape'
import { Link, useParams } from 'react-router-dom'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import { PageWrapper } from '@src/components/Page/Page'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { getDynamicGroupsForEmployee } from '@src/api/dynamicGroups'
import LapeSenioritySelector from '@src/features/SenioritySelector/LapeSenioritySelector'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { getContractingCountries, useLocationCurrency } from '@src/api/entities'
import { IdAndName, Statuses } from '@src/interfaces'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { navigateReplace } from '@src/actions/RouterActions'
import {
  useGetContractsSettings,
  useGetEmployeeSettings,
  useGetOrganisationSettings,
  useGetPayrollSettings,
} from '@src/api/settings'
import { useSubsenioritySelector } from '@src/hooks/useSubsenioritySelector'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getRecommendedJobTitle } from '@src/api/offerCreation'
import Tooltip from '@components/Tooltip/Tooltip'
import { ArrowRepeat, Pencil } from '@revolut/icons'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { ContractTypeOption } from '@src/features/Contracts/ContractTypeOption'
import { selectFeatureFlags } from '@src/store/auth/selectors'

const NO_PAYGROUP_ERROR =
  'No paygroup exists for this entity and location combination. Please change your selection.'

const NO_CONTRACTING_COUNTRY_ERROR =
  'External hiring is not allowed for this entity and location combination. Please change your selection.'

interface State {
  loading: boolean
  paygroupError?: string
  employee?: EmployeeInterface
  dynamicGroups: DynamicGroupIDs
  contractingCountry: IdAndName | null
  contractingCountryError?: string
}

const SaveWithPopup = ({
  id,
  employeeId,
  onError,
  disabled,
}: {
  id?: string
  employeeId: string
  onError: () => void
  disabled?: boolean
}) => (
  <NewSaveButtonWithPopup
    disabled={disabled}
    successText={
      id
        ? 'Employee contract successfully updated.'
        : 'Employee contract successfully created.'
    }
    onAfterSubmit={res => {
      navigateReplace(
        pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, { id: res.id, employeeId }),
      )
    }}
    onSubmitError={() => {
      onError()
    }}
    useValidator
  >
    {id ? 'Confirm' : 'Submit'}
  </NewSaveButtonWithPopup>
)

type Props = {
  setForm: (form: LapeFormInterface<EmployeeContractsInterface>) => void
}
export const ContractForm = ({ setForm }: Props) => {
  const form = useLapeContext<EmployeeContractsInterface>()
  const { values, initialValues } = form
  const { employeeId, id } = useParams<{ employeeId: any; id: any }>()
  const { data: contractsSettings } = useGetContractsSettings()

  const [isSubmitPopupOpened, setIsSubmitPopupOpened] = useState<boolean>(false)
  const [editingJobTitle, setEditingJobTitle] = useState(false)
  const [confirmEditingJobTitlePopupOpen, setConfirmEditingJobTitlePopupOpen] =
    useState(false)
  const [recommendedJobTitle, setRecommendedJobTitle] = useState<string>()

  const { data: settings } = useGetEmployeeSettings()
  const { data: organisationSettings } = useGetOrganisationSettings()
  const enableLocationLimitations = !!organisationSettings?.enable_location_limitations

  const state = useLape<State>({
    loading: !!employeeId,
    paygroupError: undefined,
    employee: undefined,
    dynamicGroups: [],
    contractingCountry: null,
  })

  const { options: contractTypeOptions } = useFetchOptions<EmployeeContractType>(
    selectorKeys.contract_types,
  )

  const employeeType = useMemo(() => {
    const contractTypeId = values.contract_type?.id
    return contractTypeOptions?.find(opt => opt.value?.id === contractTypeId)?.value
      .employee_type
  }, [contractTypeOptions, values.contract_type])

  const { data: payrollSettings } = useGetPayrollSettings()
  const featureFlags = useSelector(selectFeatureFlags)
  const showPaygroupInput =
    employeeType === 'internal' &&
    payrollSettings?.enabled &&
    !featureFlags.includes(FeatureFlags.PayrollV2)

  useEffect(() => {
    setForm(form)
  }, [])

  useEffect(() => {
    if (values.specialisation?.name !== initialValues.specialisation?.name) {
      values.title = values.specialisation ? values.specialisation.name : ''
    }
  }, [values.specialisation])

  const fetchRecommendedJobTitle = useCallback(async () => {
    if (!values.seniority || !values.specialisation) {
      return {}
    }
    const { data } = await getRecommendedJobTitle(
      values.seniority.id,
      values.specialisation.id,
    )

    return {
      jobTitle: data.job_title,
      isRecommendationEnabled: data.is_offer_job_title_suggestion_enabled,
    }
  }, [values.seniority, values.specialisation])

  useEffect(() => {
    const fetchEmployee = async () => {
      const { data } = await employeesRequests.getItem(employeeId)
      state.employee = data
      state.loading = false

      if (!values.location) {
        values.location = data.location
      }
      if (!values.specialisation) {
        values.specialisation = data.specialisation
      }
      if (!values.seniority) {
        values.seniority = data.seniority
      }
      if (!values.salary_time_unit) {
        values.salary_time_unit = { id: 'year', name: 'Year' }
      }
      if (!values.notice_period_during_probation_unit) {
        values.notice_period_during_probation_unit = { id: 'week', name: 'Week' }
      }
      if (!values.notice_period_after_probation_unit) {
        values.notice_period_after_probation_unit = { id: 'week', name: 'Week' }
      }
      if (values.is_primary_contract === undefined) {
        values.is_primary_contract = true
      }
    }

    const fetchDynamicGroups = () => {
      getDynamicGroupsForEmployee(employeeId).then(res => {
        state.dynamicGroups = res.data.results
      })
    }

    const initRecommendedJobTitle = async () => {
      const { jobTitle, isRecommendationEnabled } = await fetchRecommendedJobTitle()

      if (!isRecommendationEnabled) {
        setEditingJobTitle(true)
        return
      }
      setRecommendedJobTitle(jobTitle)

      if (values.title && values.title !== jobTitle) {
        setEditingJobTitle(true)
      } else if (!values.title) {
        values.title = jobTitle
      }
    }

    fetchEmployee()
    fetchDynamicGroups()
    initRecommendedJobTitle()
  }, [])

  useEffect(() => {
    /** If location limitations are disabled, doesn't make sense to fetch and set the paygroup or hiring countries */
    if (!enableLocationLimitations) {
      return
    }

    const filters = values.location
      ? [
          {
            filters: [{ id: values.location.id, name: values.location.location_name }],
            columnName: 'country__locations__id',
          },
          {
            filters: [{ id: Statuses.active, name: Statuses.active }],
            columnName: 'status',
          },
        ]
      : []

    const fetchPaygroups = async () => {
      if (!showPaygroupInput) {
        return
      }
      const { data } = await getEmployeePaygroup({
        filters: [
          ...filters,
          {
            filters: [
              { id: values.company_entity!.id, name: values.company_entity!.name },
            ],
            columnName: 'company_entity__id',
          },
        ],
      })

      if (data?.results?.[0]) {
        values.pay_group = data.results[0]
        state.paygroupError = undefined
      } else {
        values.pay_group = undefined
        state.paygroupError = NO_PAYGROUP_ERROR
      }
    }

    const fetchHiringCountries = async () => {
      const { data } = await getContractingCountries({
        filters: [
          ...filters,
          {
            filters: [
              { id: values.company_entity!.id, name: values.company_entity!.name },
            ],
            columnName: 'entity__id',
          },
        ],
      })

      if (data?.results?.[0]) {
        state.contractingCountry = data.results[0].country
        state.contractingCountryError = undefined
      } else {
        state.contractingCountry = null
        state.contractingCountryError = NO_CONTRACTING_COUNTRY_ERROR
      }
    }

    const isInternal = employeeType === 'internal'
    const isExternal = employeeType === 'external'

    if (values.location && values.company_entity && isInternal) {
      fetchPaygroups()
      state.contractingCountry = null
      state.contractingCountryError = undefined
    }
    if (values.location && values.company_entity && isExternal) {
      fetchHiringCountries()
      values.pay_group = undefined
      state.paygroupError = undefined
    }
  }, [values.location, values.company_entity, employeeType, enableLocationLimitations])

  const subsenioritySelector = useSubsenioritySelector<EmployeeContractsInterface>(
    values,
    initialValues,
  )

  const onAfterSeniorityChange = async () => {
    const { jobTitle, isRecommendationEnabled } = await fetchRecommendedJobTitle()

    if (isRecommendationEnabled) {
      setRecommendedJobTitle(jobTitle)
      values.title = jobTitle
    }
  }

  const canChangeCurrency =
    !values.id ||
    values.field_options?.permissions?.includes(
      PermissionTypes.CanAllowInternalCurrencyChangeEmployeeContract,
    )

  const currency = useLocationCurrency(values.location?.id)
  const currencyByLocation = currency.data?.currency

  useEffect(() => {
    if (canChangeCurrency && !values.salary_currency) {
      values.salary_currency = currencyByLocation
    }
  }, [canChangeCurrency, currencyByLocation?.id])

  const isReasonForChangeRequired =
    id &&
    values.reason_for_change_required &&
    contractsSettings?.change_justification_enabled

  if (state.loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }
  return (
    <>
      <PageBody>
        {values.id && (
          <ActionWidget
            title="Contracts should only be edited to correct mistakes, typos or minor errors"
            text="If an employee’s contract details are changing (salary, location, seniority, role, etc) then a new contract should be created to reflect those changes."
          >
            <MoreBar.Action
              use={Link}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, { employeeId })}
            >
              Create new contract
            </MoreBar.Action>
          </ActionWidget>
        )}
        <AutoStepper>
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CONTRACTS}
            dynamicGroups={state.dynamicGroups}
          >
            <NewStepperTitle title="General" />
            <InputGroup>
              <LapeRadioSelectInput<EmployeeContractType>
                name="contract_type"
                selector={selectorKeys.contract_types}
                label="Contract Type"
              >
                {option => <ContractTypeOption option={option} />}
              </LapeRadioSelectInput>
              <Input
                label="Employee type"
                value={employeeType ? upperFirst(employeeType) : ''}
                disabled
              />
              <LapeRadioSelectInput
                name="contract_term"
                selector={selectorKeys.contract_terms}
                label="Contract Term"
              />
              {values.contract_status && (
                <LapeRadioSelectInput
                  label="Status"
                  name="contract_status"
                  disabled
                  message="Pending to active when the start date is reached, active to inactive manually, inactive back to active when the end of inactivity is reached"
                />
              )}
              <InputGroup variant="horizontal">
                <LapeDatePickerInput
                  name="start_date"
                  label="Start date"
                  placeholder="YYYY-MM-DD"
                  required
                  message="Effective date of the current employment terms"
                />
                <LapeDatePickerInput
                  label="End date"
                  placeholder="YYYY-MM-DD"
                  required={values?.contract_term?.id !== 'indefinite'}
                  name="end_date"
                  message="Expiration date of the contract"
                />
              </InputGroup>
              <InputGroup variant="horizontal">
                <LapeRadioSelectInput
                  label="Location"
                  name="location"
                  selector={selectorKeys.location}
                  message="By default current location"
                />
                <LapeRadioSelectInput
                  label="Entity"
                  name="company_entity"
                  selector={selectorKeys.active_entities}
                  message="Entity for which the employee will work for"
                />
              </InputGroup>

              {showPaygroupInput ? (
                <LapeRadioSelectInput
                  label="Paygroup"
                  name="pay_group"
                  selector={selectorKeys.active_pay_groups}
                  disabled={enableLocationLimitations}
                  message={
                    enableLocationLimitations
                      ? state.paygroupError || 'A function of location and entity'
                      : null
                  }
                  hasError={!!state.paygroupError}
                />
              ) : null}
              {employeeType === 'external' && enableLocationLimitations ? (
                <Input
                  label="External hiring location"
                  errorMessage={state.contractingCountryError}
                  aria-invalid={!!state.contractingCountryError}
                  value={state.contractingCountry?.name || ''}
                  disabled
                />
              ) : null}
              {contractsSettings?.secondary_contracts_enabled ? (
                <LapeNewRadioButtons
                  name="is_primary_contract"
                  options={[
                    { value: true, label: 'Primary contract' },
                    { value: false, label: 'Secondary contract' },
                  ]}
                  variant="cell"
                />
              ) : null}
              {contractsSettings?.link_to_justification_enabled ? (
                <LapeNewInput
                  name="link_to_justification"
                  label="Link to justification"
                  required
                />
              ) : null}
            </InputGroup>
          </NewStepperSectionCustomFields>
          <NewStepperTitle title="Salary" />
          <InputGroup>
            <InputGroup variant="horizontal">
              <LapeNewInput
                label="Base salary amount"
                name="salary_amount"
                type="money"
                required
                message="Gross salary as per the employment contract"
                width="50%"
              />
              {canChangeCurrency ? (
                <LapeRadioSelectInput
                  label="Currency"
                  name="salary_currency"
                  selector={selectorKeys.currencies}
                  required
                />
              ) : (
                <Input
                  label="Currency"
                  value={currencyByLocation?.iso_code || ''}
                  disabled
                />
              )}
            </InputGroup>
            <InputGroup variant="horizontal">
              <LapeRadioSelectInput
                label="Time unit"
                name="salary_time_unit"
                selector={selectorKeys.employee_contract_time_unit}
              />
              <LapeRadioSelectInput
                label="Payment frequency"
                name="salary_payment_frequency"
                selector={selectorKeys.employee_contract_payment_frequency}
              />
            </InputGroup>
          </InputGroup>

          <NewStepperTitle title="Joining bonus" />
          <InputGroup>
            <LapeRadioSelectInput
              name="sign_on_bonus_type"
              label="Bonus type"
              selector={selectorKeys.contract_sign_on_bonus_types}
            />

            {values.sign_on_bonus_type == null ||
            values.sign_on_bonus_type.id === 'no_bonus' ? null : (
              <InputGroup variant="horizontal">
                <LapeNewInput
                  label="Bonus amount"
                  name="sign_on_bonus_amount"
                  type="money"
                  width="50%"
                  required
                />
                <LapeRadioSelectInput
                  label="Currency"
                  name="sign_on_bonus_currency"
                  selector={selectorKeys.currencies}
                />
              </InputGroup>
            )}
          </InputGroup>

          <NewStepperTitle title="Role" />
          <InputGroup>
            <LapeRadioSelectInput
              name="specialisation"
              label="Specialisation"
              selector={selectorKeys.specialisations}
              onAfterChange={() => {
                if (values.specialisation_seniority_sublevel) {
                  values.specialisation_seniority_sublevel = undefined
                }
              }}
            />
            <LapeSenioritySelector
              name="seniority"
              required
              label="Seniority"
              specialisationId={values.specialisation?.id || null}
              message="Required level of expertise or prospective level of authority"
              onAfterChange={onAfterSeniorityChange}
            />
            {!!organisationSettings?.enable_multiple_levels_per_seniority && (
              <LapeRadioSelectInput
                name="specialisation_seniority_sublevel"
                label="Sub-seniority Level"
                selector={subsenioritySelector}
              />
            )}
            {!!settings?.enable_job_title && (
              <LapeNewInput
                name="title"
                label="Job title"
                required
                disabled={!editingJobTitle}
                renderAction={() => {
                  if (!recommendedJobTitle) {
                    return null
                  }
                  return editingJobTitle ? (
                    <Tooltip
                      placement="top"
                      text="Reset the job title to the recommended"
                    >
                      <IconButton
                        onClick={() => {
                          values.title = recommendedJobTitle
                          setEditingJobTitle(false)
                        }}
                        useIcon={ArrowRepeat}
                        data-testid="btn-reset-job-title"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" text="Change the job title">
                      <IconButton
                        onClick={() => {
                          setConfirmEditingJobTitlePopupOpen(true)
                        }}
                        useIcon={Pencil}
                        color={Color.FOREGROUND}
                        data-testid="btn-change-job-title"
                      />
                    </Tooltip>
                  )
                }}
              />
            )}
          </InputGroup>

          <NewStepperTitle title="Employment" />
          <InputGroup>
            <InputGroup variant="horizontal">
              <LapeNewInput
                label="Weekly working hours"
                name="weekly_working_hours"
                type="number"
                required
                message="Contractual hours worked per week, as per employment contract"
                width="50%"
              />
              <LapeNewInput
                label="Full time equivalent"
                name="full_time_equivalent"
                type="number"
                required
                message="Ratio from 0-1 based on number of hours worked per week compared to full time"
              />
            </InputGroup>
          </InputGroup>

          <Subheader variant="nested">
            <Subheader.Title>Notice period</Subheader.Title>
          </Subheader>
          <InputGroup>
            <InputGroup variant="horizontal">
              <LapeNewInput
                label="During probation"
                name="notice_period_during_probation"
                type="number"
                required
                message="Notice period as per the employment contract during probation period"
                width="50%"
              />
              <LapeRadioSelectInput
                label="Time unit"
                name="notice_period_during_probation_unit"
                selector={selectorKeys.employee_contract_probation_unit}
              />
            </InputGroup>

            <InputGroup variant="horizontal">
              <LapeNewInput
                label="After probation"
                name="notice_period_after_probation"
                type="number"
                required
                message="Notice period as per the employment contract after probation period "
                width="50%"
              />
              <LapeRadioSelectInput
                label="Time unit"
                name="notice_period_after_probation_unit"
                selector={selectorKeys.employee_contract_probation_unit}
              />
            </InputGroup>
          </InputGroup>

          {contractsSettings?.documents_enabled ? (
            <Box mb="s-24">
              <NewStepperTitle title="Document" />
              <EmployeeDocSectionLape
                initialValues={{
                  confidentiality: { id: 'hr_manager', name: 'HR Manager' },
                  category: { id: 1, name: 'Employment contract - Full time' },
                }}
                employee={state.employee}
              />
            </Box>
          ) : null}
        </AutoStepper>
      </PageBody>
      <PageActions>
        {isReasonForChangeRequired ? (
          <Button onClick={() => setIsSubmitPopupOpened(true)}>Submit</Button>
        ) : (
          <SaveWithPopup
            employeeId={employeeId}
            onError={() => setIsSubmitPopupOpened(false)}
          />
        )}
      </PageActions>
      <Popup
        open={isSubmitPopupOpened}
        onClose={() => setIsSubmitPopupOpened(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Text fontSize="h5">
            Please provide a justification for editing this contract
          </Text>
          <Header.Subtitle>
            Contracts should only be edited in special cases. Please provide the approver
            with a summary of the reason(s) why you are making those changes.
          </Header.Subtitle>
        </Header>
        <LapeNewInput required name="reason_for_change" label="Reason for change" />
        <Popup.Actions horizontal>
          <Button onClick={() => setIsSubmitPopupOpened(false)} variant="secondary">
            Go back
          </Button>
          <SaveWithPopup
            id={id}
            employeeId={employeeId}
            onError={() => setIsSubmitPopupOpened(false)}
            disabled={!values.reason_for_change}
          />
        </Popup.Actions>
      </Popup>
      <ConfirmationDialog
        open={confirmEditingJobTitlePopupOpen}
        onClose={() => setConfirmEditingJobTitlePopupOpen(false)}
        onConfirm={() => {
          setEditingJobTitle(true)
          setConfirmEditingJobTitlePopupOpen(false)
        }}
        onReject={() => setConfirmEditingJobTitlePopupOpen(false)}
        label="Are you sure you want to change the job title?"
        yesMessage="Change"
        noMessage="Cancel"
      />
    </>
  )
}
